import React from "react";
import { Link, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Section from "../components/section";
import { Button } from "../components/button";
import CTADemo from "../components/sections/cta-demo";
import { GatsbyImage } from "gatsby-plugin-image";

export default function Blog({ data }) {
  return (
    <Layout>
      <div>
        <section
          id="hero"
          className="relative bg-slate-900/70 after:bg-slate-900/20 after:block after:h-full after:absolute after:right-0 after:left-0 after:top-0 after:content-['']"
        >
          <div className="z-0 absolute left-0 bottom-0 right-0 top-0 overflow-hidden">
            <StaticImage
              alt="features"
              src="../assets/Coeus-Blog-Hero.jpeg"
              className="w-full h-full"
              imgClassName="object-cover w-full h-full"
              layout="fullWidth"
            />
          </div>

          <div className="z-10 relative flex h-full items-center justify-center text-center px-8 py-28 sm:py-48 xl:px-0 container max-w-7xl mx-auto">
            <div className="max-w-sm sm:max-w-md md:max-w-xl text-white">
              <div className="space-y-2">
                <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold">
                  Blog
                </h1>
                <p className="text-lg md:text-xl font-normal">
                  Read articles about AI & Chatbots
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Section>
        <div className="space-y-16 lg:space-y-24">
          {data.allWpPost.nodes.map((node) => (
            <div className="font-openSans lg:mx-auto lg:grid lg:grid-cols-2 lg:items-center lg:gap-24">
              {node.featuredImage && (
                <GatsbyImage
                  image={
                    node.featuredImage.node.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  alt={node.featuredImage.node.altText}
                  className="overflow-hidden rounded-xl h-full"
                />
              )}
              <div className="text-base py-8 md:text-lg font-light">
                <h1 className="text-3xl md:text-4xl font-bold pb-4">
                  {node.title}
                </h1>
                <p className="pb-4">Published: {node.modified}</p>
                <div 
                  className="pb-4" 
                  dangerouslySetInnerHTML={{ __html: node.excerpt }}
                />
                <Link to={`/blog/${node.slug}/`}>
                  <Button>Read Full Article</Button>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </Section>

      <CTADemo />
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    allWpPost(sort: { fields: [date], order: DESC }) {
      nodes {
        title
        excerpt
        slug
        modified(formatString: "MMM DD, YYYY")
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  layout: FULL_WIDTH
                  placeholder: TRACED_SVG
                )
              }
            }
          }
        }
      }
    }
  }
`;

export const Head = () => {
  return (
    <SEO
      title="Blog"
      description="Coeus is a customer service platform that utilizes Conversational AI to provide a unique, high-quality customer experience."
    ></SEO>
  );
};
